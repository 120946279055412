@import "~bulma/bulma";

// roots-shimane-frontの拡張
$c-sub: #ffcc18;

.c-btn {
  input {
    font-size: 18px;
    font-weight: 500;
    color: #000 !important;
    display: block;
    background-color: $c-sub;
    text-align: center;
    border-radius: 50px;
    min-width: 166px;
    padding: 10px 20px;
    text-decoration: none;
    border: none;
    cursor: pointer;
    cursor: hand;
  }

  button {
    font-size: 18px;
    font-weight: 600;
    color: #000 !important;
    display: block;
    background-color: $c-sub;
    text-align: center;
    border-radius: 50px;
    min-width: 166px;
    padding: 10px 20px;
    text-decoration: none;
    border: none;
    &.disabled {
      opacity: 0.7;
    }
  }

  &.-lg {
    input {
      min-width: 266px;
    }
  }
}

.home-hito__card {
  &.-n1 {
    div {
      background-image: url(/assets/img/home/hito-img1.jpg);
    }
  }
  &.-n2 {
    div {
      margin-top: 46px;
      background-image: url(/assets/img/home/hito-img2.jpg);
    }
  }
  &.-n3 {
    div {
      margin-top: 92px;
      background-image: url(/assets/img/home/hito-img3.jpg);
    }
  }
  &.-n4 {
    div {
      margin-top: 138px;
      background-image: url(/assets/img/home/hito-img4.jpg);
    }
  }
}

.home-hito__card {
  div {
    height: 300px;
    display: block;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100% auto;
    padding: 10px;
  }
}

.home-koto__news li p {
  display: block;
  padding: 3px 0;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.2em;
}
